import { useTranslation } from "react-i18next";

import styles from "./UnauthorizedPage.module.scss";

const UnauthorizedPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        {t("unauthorizedPage.headerText")}
      </div>
    </div>
  );
};

export default UnauthorizedPage;
